@font-face {
  font-family: 'GT-Super-Text-Black-Italic';
  src: url('/webFonts/GT-Super/GT-Super-Text-Black-Italic.woff2')
      format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Black-Italic.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Black';
  src: url('/webFonts/GT-Super/GT-Super-Text-Black.woff2') format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Black.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Bold-Italic';
  src: url('/webFonts/GT-Super/GT-Super-Text-Bold-Italic.woff2') format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Bold-Italic.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Bold';
  src: url('/webFonts/GT-Super/GT-Super-Text-Bold.woff2') format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Bold.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Book-Italic';
  src: url('/webFonts/GT-Super/GT-Super-Text-Book-Italic.woff2') format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Book-Italic.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Book';
  src: url('/webFonts/GT-Super/GT-Super-Text-Book.woff2') format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Book.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Medium-Italic';
  src: url('/webFonts/GT-Super/GT-Super-Text-Medium-Italic.woff2')
      format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Medium-Italic.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Medium';
  src: url('/webFonts/GT-Super/GT-Super-Text-Medium.woff2') format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Medium.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Regular-Italic';
  src: url('/webFonts/GT-Super/GT-Super-Text-Regular-Italic.woff2')
      format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Regular-Italic.woff') format('woff');
}

@font-face {
  font-family: 'GT-Super-Text-Regular';
  src: url('/webFonts/GT-Super/GT-Super-Text-Regular.woff2') format('woff2'),
    url('/webFonts/GT-Super/GT-Super-Text-Regular.woff') format('woff');
}

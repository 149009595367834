@font-face {
  font-family: 'AvertaCY-Extrathin';
  src: url('/webFonts/AvertaCYExtrathin/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYExtrathin/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-ExtraboldItalic';
  src: url('/webFonts/AvertaCYExtraboldItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYExtraboldItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-SemiboldItalic';
  src: url('/webFonts/AvertaCYSemiboldItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYSemiboldItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-Regular';
  src: url('/webFonts/AvertaCYRegular/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYRegular/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-Bold';
  src: url('/webFonts/AvertaCYBold/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYBold/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-Extrabold';
  src: url('/webFonts/AvertaCYExtrabold/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYExtrabold/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-Thin';
  src: url('/webFonts/AvertaCYThin/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYThin/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-Light';
  src: url('/webFonts/AvertaCYLight/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYLight/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-RegularItalic';
  src: url('/webFonts/AvertaCYRegularItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYRegularItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-Black';
  src: url('/webFonts/AvertaCYBlack/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYBlack/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-LightItalic';
  src: url('/webFonts/AvertaCYLightItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-BoldItalic';
  src: url('/webFonts/AvertaCYBoldItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-BlackItalic';
  src: url('/webFonts/AvertaCYBlackItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYBlackItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-ExtrathinItalic';
  src: url('/webFonts/AvertaCYExtrathinItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYExtrathinItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-ThinItalic';
  src: url('/webFonts/AvertaCYThinItalic/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYThinItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'AvertaCY-Semibold';
  src: url('/webFonts/AvertaCYSemibold/font.woff2') format('woff2'),
    url('/webFonts/AvertaCYSemibold/font.woff') format('woff');
}

@import url('//hello.myfonts.net/count/3f6df3');

@font-face {
  font-family: 'Gotham-Black';
  src: url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Black-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-BlackIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('/webFonts/Gotham-Font/Gotham-Bold.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Bold-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-BlackIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('/webFonts/Gotham-Font/Gotham-Book.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Book-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-BookIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Light';
  src: url('/webFonts/Gotham-Font/Gotham-Light.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Light-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-LightIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('/webFonts/Gotham-Font/Gotham-Medium.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Medium-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-MediumIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Thin';
  src: url('/webFonts/Gotham-Font/Gotham-Thin.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Thin-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-ThinIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Ultra';
  src: url('/webFonts/Gotham-Font/Gotham-Ultra.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-Ultra-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-UltraIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff');
}

@font-face {
  font-family: 'Gotham-XLight';
  src: url('/webFonts/Gotham-Font/Gotham-XLight.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff2');
}

@font-face {
  font-family: 'Gotham-XLight-Italic';
  src: url('/webFonts/Gotham-Font/Gotham-XLightIta.otf') format('woff2'),
    url('/webFonts/Gotham-Font/Gotham-Black.otf') format('woff2');
}
